// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-bezpieczenstwo-w-trakcie-pracy-na-wysokosci-5-najwazniejszych-wskazowek-js": () => import("./../../../src/pages/blog/bezpieczenstwo-w-trakcie-pracy-na-wysokosci-5-najwazniejszych-wskazowek.js" /* webpackChunkName: "component---src-pages-blog-bezpieczenstwo-w-trakcie-pracy-na-wysokosci-5-najwazniejszych-wskazowek-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-jak-skutecznie-wynajac-zwyzke-lub-podnosnik-koszowy-js": () => import("./../../../src/pages/blog/jak-skutecznie-wynajac-zwyzke-lub-podnosnik-koszowy.js" /* webpackChunkName: "component---src-pages-blog-jak-skutecznie-wynajac-zwyzke-lub-podnosnik-koszowy-js" */),
  "component---src-pages-blog-nowy-podnosnik-w-naszej-ofercie-28-m-wysokosci-roboczej-js": () => import("./../../../src/pages/blog/nowy-podnosnik-w-naszej-ofercie-28m-wysokosci-roboczej.js" /* webpackChunkName: "component---src-pages-blog-nowy-podnosnik-w-naszej-ofercie-28-m-wysokosci-roboczej-js" */),
  "component---src-pages-blog-rozszerzamy-zasieg-swiadczenia-naszych-uslug-js": () => import("./../../../src/pages/blog/rozszerzamy-zasieg-swiadczenia-naszych-uslug.js" /* webpackChunkName: "component---src-pages-blog-rozszerzamy-zasieg-swiadczenia-naszych-uslug-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-sprzet-js": () => import("./../../../src/pages/sprzet.js" /* webpackChunkName: "component---src-pages-sprzet-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */)
}

